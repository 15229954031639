import styled from 'styled-components'
import Box from './Box'

const ShadowFrame = styled(Box)``

ShadowFrame.defaultProps = {
  boxShadow: 'small',
  borderRadius: 24,
  // Apply border-radius to all children
  overflow: 'hidden',
  isolation: 'isolate',
}

export default ShadowFrame
